import { NavigationProp } from '@react-navigation/native';
import { TranslationKeys } from 'ehawker/src/ui/locale/locale';
import { OrderDetailPage } from 'ehawker/src/ui/pages/Order/OrderDetail/OrderDetailPage';

import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { DoListTab } from '../pages/Dashboard/DeliveryOrder/DoListTab';
import { DashboardProfile } from '../pages/Dashboard/Profile/DashboardProfile';
import { ScannedQRDoList } from '../pages/Dashboard/Qr/ScannedQRDoList';
import ChangePasswordPage from '../pages/Login/ChangePasswordPage';
import ForgetPasswordPage from '../pages/Login/ForgetPasswordPage';
import LoginPage from '../pages/Login/LoginPage';

export enum RouteNames {
  Dashboard = 'Dashboard',
  Login = 'Login',
  ForgetPassword = 'ForgetPassword',
  ChangePassword = 'ChangePassword',
  DashboardProfile = 'DashboardProfile',
  DoDetails = 'DoDetails',
  DoListTab = 'DoListTab',
  ScanQRList = 'ScannedQRDoList',
  NewOrder = 'NewOrder',
}

type DefinedRouteParams = {
  [RouteNames.DoDetails]: { orderSn: number; doSn?: number | null };
  [RouteNames.DoListTab]: { index: number };
  [RouteNames.ScanQRList]: { hscode: string };
  [RouteNames.NewOrder]: {
    hscode?: string;
  };
};

type EmptyRouteParams = {
  [key in Exclude<RouteNames, keyof DefinedRouteParams>]: undefined;
};

export type RouteParams = EmptyRouteParams & DefinedRouteParams;

export type Route = {
  name: RouteNames;
  title: TranslationKeys | '';
  component: (props: any) => JSX.Element;
  backButton: boolean;
  authRequired: boolean;
};

export const ROUTES: Record<RouteNames, Route> = {
  [RouteNames.Dashboard]: {
    name: RouteNames.Dashboard,
    title: 'dashboard',
    component: DashboardPage,
    backButton: false,
    authRequired: true,
  },
  [RouteNames.Login]: {
    name: RouteNames.Login,
    title: '',
    component: LoginPage,
    backButton: false,
    authRequired: false,
  },
  [RouteNames.ForgetPassword]: {
    name: RouteNames.ForgetPassword,
    title: 'forgetpassword',
    component: ForgetPasswordPage,
    backButton: true,
    authRequired: false,
  },
  [RouteNames.ChangePassword]: {
    name: RouteNames.ChangePassword,
    title: 'changepassword',
    component: ChangePasswordPage,
    backButton: false,
    authRequired: true,
  },
  [RouteNames.DashboardProfile]: {
    name: RouteNames.DashboardProfile,
    title: 'profile',
    component: DashboardProfile,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.NewOrder]: {
    name: RouteNames.NewOrder,
    title: 'new_order',
    component: OrderDetailPage,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.DoDetails]: {
    name: RouteNames.DoDetails,
    title: 'delivery_order_details',
    component: OrderDetailPage,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.DoListTab]: {
    name: RouteNames.DoListTab,
    title: 'dashboard',
    component: DoListTab,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.ScanQRList]: {
    name: RouteNames.ScanQRList,
    title: 'dashboard',
    component: ScannedQRDoList,
    backButton: true,
    authRequired: true,
  },
};

export type NavigationProps = NavigationProp<RouteParams>;

export function getRouteByName(name: string): Route | undefined {
  return Object.values(ROUTES).find((route) => route.name === name);
}
