import { zodResolver } from '@hookform/resolvers/zod';
import { StackScreenProps } from '@react-navigation/stack';
import { useTranslate } from 'base/src/ui/locale/locale';
import {
  OrderListRequestSchema,
  OrderListRequestZodSchema,
} from 'ehawker/src/data/schemas/order/OrderListRequestSchema';
import { OrderStatus } from 'ehawker/src/data/schemas/order/OrderSchema';
import moment from 'moment';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dimensions } from 'react-native';
import { Badge, useTheme } from 'react-native-paper';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { z } from 'zod';

import { DoListFilter } from './DoListFilter';
import { DoListTabScreen } from './DoListTabScreen';
import { RouteNames, RouteParams } from '../../../navigation/Routes';
import { TabContainer } from '../TabContainer';

export function DoListTab({
  route,
}: StackScreenProps<RouteParams, RouteNames.DoListTab>) {
  const index = route.params?.index;
  const { colors } = useTheme();
  const { t } = useTranslate();
  const today = moment().format('YYYY-MM-DD');
  const [tabs, setTabs] = useState({
    [OrderStatus.New]: {
      count: 0,
      label: t('new'),
    },
    [OrderStatus.Prepared]: {
      count: 0,
      label: t('prepared'),
    },
    [OrderStatus.Delivered]: {
      count: 0,
      label: t('delivered'),
    },
    [OrderStatus.Completed]: {
      count: 0,
      label: t('completed'),
    },
    [OrderStatus.Cancelled]: {
      count: 0,
      label: t('cancelled'),
    },
  });

  const form = useForm<OrderListRequestSchema>({
    resolver: zodResolver(OrderListRequestZodSchema),
    defaultValues: {
      _expectedDateFrom: today,
      _expectedDateTo: today,
    },
  });
  const watchedFormValues = form.watch();
  const [tabIndex, setTabIndex] = useState(index);
  const routes = Object.entries(tabs).map(([key, value]) => ({
    key,
    title: value.label,
  }));

  return (
    <TabContainer hscode={watchedFormValues._hscode ?? undefined}>
      <FormProvider {...form}>
        <DoListFilter />
        <TabView
          onIndexChange={setTabIndex}
          navigationState={{ index: tabIndex, routes }}
          renderScene={SceneMap({
            ...Object.fromEntries(
              Object.entries(tabs).map(([key, value]) => [
                key,
                () => (
                  <DoListTabScreen
                    label={value.label}
                    badge={value.count > 0 ? value.count : undefined}
                    setBadge={(count, orderStatus) => {
                      if (tabs[orderStatus].count === count) return;
                      setTabs((prev) => ({
                        ...prev,
                        [orderStatus]: {
                          // @ts-ignore
                          ...prev[orderStatus],
                          count,
                        },
                      }));
                    }}
                    orderListRequest={{
                      ...watchedFormValues,
                      _status: z.nativeEnum(OrderStatus).parse(key),
                    }}
                  />
                ),
              ]),
            ),
          })}
          renderTabBar={(props) => (
            <TabBar
              {...props}
              labelStyle={{ color: 'black', textTransform: 'none' }}
              indicatorStyle={{ backgroundColor: colors.primary }}
              tabStyle={{ width: 120 }}
              scrollEnabled
              style={{ backgroundColor: colors.background }}
              renderBadge={({ route }) =>
                tabs[route.key].count ? (
                  <Badge>{tabs[route.key].count}</Badge>
                ) : undefined
              }
            />
          )}
          initialLayout={{
            width: Dimensions.get('window').width,
            height: 100,
          }}
        />

        {/*<TabsProvider defaultIndex={index}>*/}
        {/*  <Tabs>*/}
        {/*    {Object.entries(tabs).map(([key, value]) => (*/}
        {/*      <DoListTabScreen*/}
        {/*        key={key}*/}
        {/*        label={value.label}*/}
        {/*        badge={value.count > 0 ? value.count : undefined}*/}
        {/*        setBadge={(count, orderStatus) => {*/}
        {/*          setTabs((prev) => ({*/}
        {/*            ...prev,*/}
        {/*            [orderStatus]: {*/}
        {/*              // @ts-ignore*/}
        {/*              ...prev[orderStatus],*/}
        {/*              count,*/}
        {/*            },*/}
        {/*          }));*/}
        {/*        }}*/}
        {/*        orderListRequest={{*/}
        {/*          ...watchedFormValues,*/}
        {/*          _status: z.nativeEnum(OrderStatus).parse(key),*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*  </Tabs>*/}
        {/*</TabsProvider>*/}
      </FormProvider>
    </TabContainer>
  );
}
