import { MaterialCommunityIcons } from '@expo/vector-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { EmployeeUserRepo } from 'ehawker/src/data/repositories/EmployeeUserRepo';
import {
  ChangePasswordRequest,
  ChangePasswordRequestSchema,
} from 'ehawker/src/data/schemas/auth/ChangePasswordRequestSchema';
import { useUser } from 'ehawker/src/ui/hooks/useUser';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Text, useTheme } from 'react-native-paper';

import { ExpandableView } from '../../../components/ExpandableView';

const log = loggerWithTag('DashboardProfile.tsx');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  passwordContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    marginTop: SPACING.xs,
  },
  buttonContainer: {
    flexDirection: 'row',
    gap: SPACING.s,
  },
  button: {
    marginTop: SPACING.xl,
    flex: 1,
    minHeight: 40,
  },
});

function CollapsedView({
  onChangePasswordPressed,
}: {
  onChangePasswordPressed: () => void;
}) {
  log.debug('DashboardProfile CollapsedView rendered');

  const { colors } = useTheme();
  const { t } = useTranslate();
  const textStyle = { color: colors.outline };

  return (
    <View style={styles.passwordContainer}>
      <View>
        <Text style={textStyle} variant="labelLarge">
          {t('password')}
        </Text>
        <Text variant="bodyLarge">{_.repeat('*', 7)}</Text>
      </View>
      <Button mode="contained-tonal" onPress={onChangePasswordPressed}>
        {t('changepassword')}
      </Button>
    </View>
  );
}

function ExpandedView({
  onCancelPressed,
  setChangePassword,
}: {
  onCancelPressed: () => void;
  setChangePassword?: (value: boolean) => void;
}) {
  log.debug('DashboardProfile ExpandedView rendered');

  const { t } = useTranslate();
  const { control, getValues, clearErrors, reset, trigger, formState } =
    useFormContext<ChangePasswordRequest>();
  const [submitting, setSubmitting] = useState(false);
  const { errorDialog } = useErrorHelper();
  const { addDialog } = useDialog();

  const onSubmitPressed = useCallback(() => {
    log.info('onSubmitPressed');
    trigger().then((isValid) => {
      if (!isValid) return;
      setSubmitting(true);
      EmployeeUserRepo.changePassword(getValues())
        .then(() => {
          addDialog({
            title: t('success'),
            message: t('password_changed'),
            onDismiss: () => {
              setChangePassword?.(false);
              reset();
            },
          });
        })
        .catch(errorDialog)
        .finally(() => setSubmitting(false));
    });
  }, [addDialog, errorDialog, getValues, reset, setChangePassword, t, trigger]);

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('current_password')}
            onChangeText={(text) => {
              onChange(text);
              clearErrors('_oldPassword');
            }}
            value={value}
            style={styles.input}
            inputError={formState.errors._oldPassword}
          />
        )}
        name="_oldPassword"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('new_password')}
            onChangeText={(text) => {
              onChange(text);
              clearErrors('_newPassword');
            }}
            value={value}
            style={styles.input}
            inputError={formState.errors._newPassword}
          />
        )}
        name="_newPassword"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('confirm_password')}
            onChangeText={(text) => {
              onChange(text);
              clearErrors('_reNewPassword');
            }}
            value={value}
            style={styles.input}
            inputError={formState.errors._reNewPassword}
          />
        )}
        name="_reNewPassword"
      />
      <View style={styles.buttonContainer}>
        <Button
          mode="contained-tonal"
          onPress={onCancelPressed}
          style={styles.button}
          disabled={submitting}
        >
          {t('cancel')}
        </Button>
        <Button
          mode="contained"
          onPress={onSubmitPressed}
          style={styles.button}
          loading={submitting}
          disabled={submitting}
        >
          {t('submit')}
        </Button>
      </View>
    </View>
  );
}

export function DashboardProfile() {
  log.debug('DashboardProfile rendered');

  const navigation = useNavigation();
  const { colors } = useTheme();
  const user = useUser();
  const { t } = useTranslate();
  const form = useForm<ChangePasswordRequest>({
    resolver: zodResolver(ChangePasswordRequestSchema),
  });
  const cardStyle = { width: '90%', backgroundColor: colors.background };
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    return navigation.addListener('blur', () => {
      setExpanded(false);
    });
  }, [navigation]);

  const onChangePasswordPressed = useCallback(() => {
    log.info('onChangePasswordPressed');
    setExpanded(true);
  }, []);

  const onCancelPressed = useCallback(() => {
    log.info('onCancelPressed');
    setExpanded(false);
  }, []);

  useEffect(() => {
    EmployeeUserRepo.afterLogin().catch(log.error);
  }, []);

  return (
    <View style={styles.container}>
      <MaterialCommunityIcons
        name="account"
        size={100}
        color={colors.outline}
      />
      <Card style={cardStyle}>
        <Card.Content>
          <FormProvider {...form}>
            <Text style={{ color: colors.outline }} variant="labelLarge">
              {t('name')}
            </Text>
            <Text variant="bodyLarge" style={{ marginBottom: SPACING.xs }}>
              {String(user?._username)}
            </Text>
            <ExpandableView
              viewKey="profile-change-password"
              containerStyle={{ width: '100%' }}
              initialExpanded={false}
              expanded={expanded}
              expandedContent={
                <ExpandedView
                  onCancelPressed={onCancelPressed}
                  setChangePassword={setExpanded}
                />
              }
              collapsedContent={
                <CollapsedView
                  onChangePasswordPressed={onChangePasswordPressed}
                />
              }
            />
          </FormProvider>
        </Card.Content>
      </Card>
    </View>
  );
}
