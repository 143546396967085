import { StackScreenProps } from '@react-navigation/stack';
import { useTranslate } from 'base/src/ui/locale/locale';
import { OrderStatus } from 'ehawker/src/data/schemas/order/OrderSchema';
import moment from 'moment';
import React, { useState } from 'react';
import { Tabs, TabsProvider } from 'react-native-paper-tabs';
import { z } from 'zod';

import { RouteNames, RouteParams } from '../../../navigation/Routes';
import { DoListTabScreen } from '../DeliveryOrder/DoListTabScreen';
import { TabContainer } from '../TabContainer';

export function ScannedQRDoList({
  route,
}: StackScreenProps<RouteParams, RouteNames.ScanQRList>) {
  const hscode = route.params?.hscode;
  const { t } = useTranslate();
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const [tabs, setTabs] = useState({
    [OrderStatus.New]: {
      count: 0,
      label: t('new'),
    },
    [OrderStatus.Prepared]: {
      count: 0,
      label: t('prepared'),
    },
  });

  return (
    <TabContainer hscode={hscode}>
      <TabsProvider>
        <Tabs>
          {Object.entries(tabs).map(([key, value]) => (
            <DoListTabScreen
              key={key}
              label={value.label}
              badge={value.count > 0 ? value.count : undefined}
              setBadge={(count, orderStatus) => {
                setTabs((prev) => ({
                  ...prev,
                  [orderStatus]: {
                    // @ts-ignore
                    ...prev[orderStatus],
                    count,
                  },
                }));
              }}
              orderListRequest={{
                _expectedDateFrom: yesterday,
                _expectedDateTo: today,
                _status: z.nativeEnum(OrderStatus).parse(key),
                _hscode: hscode,
              }}
            />
          ))}
        </Tabs>
      </TabsProvider>
    </TabContainer>
  );
}
