import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { SelectDialog } from 'base/src/ui/dialogs/SelectDialog';
import { useStoreList } from 'ehawker/src/ui/query/useStoreList';
import _ from 'lodash';
import React, { ReactNode, useState } from 'react';
import { View } from 'react-native';
import { FAB } from 'react-native-paper';

import { NavigationProps, RouteNames } from '../../navigation/Routes';

export function TabContainer({
  children,
  hscode,
}: {
  children: ReactNode;
  hscode?: string;
}) {
  const navigation = useNavigation<NavigationProps>();
  const [selectDialogVisible, setSelectDialogVisible] = useState(false);
  const { storeList } = useStoreList();
  hscode = _.isEmpty(hscode) ? undefined : hscode;

  function createOrder() {
    if (!hscode) {
      setSelectDialogVisible(true);
      return;
    }
    navigation.navigate(RouteNames.NewOrder, { hscode });
  }

  return (
    <View
      style={{
        height: '100%',
      }}
    >
      {children}
      <FAB
        icon="plus"
        style={{
          position: 'absolute',
          bottom: SPACING.s,
          right: SPACING.s,
        }}
        onPress={createOrder}
      />
      <SelectDialog
        search
        inputList={storeList.map((store) => ({
          label: store._hawkerStore ?? '',
          value: store._hscode ?? '',
        }))}
        onSelected={(hscode) => {
          setSelectDialogVisible(false);
          navigation.navigate(RouteNames.NewOrder, { hscode: hscode.value });
        }}
        visible={selectDialogVisible}
        onDismiss={() => {
          setSelectDialogVisible(false);
        }}
      />
    </View>
  );
}
