import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { SelectTextInput } from 'base/src/ui/components/input/SelectTextInput';
import { useTranslate } from 'base/src/ui/locale/locale';
import { OrderListRequestSchema } from 'ehawker/src/data/schemas/order/OrderListRequestSchema';
import { useStoreList } from 'ehawker/src/ui/query/useStoreList';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import {
  Badge,
  Button,
  Checkbox,
  List,
  MD2Colors,
  Text,
} from 'react-native-paper';

export const doListFilterExpandedAtom = atom(false);

export function DoListFilter() {
  const { t } = useTranslate();
  const form = useFormContext<OrderListRequestSchema>();
  const [filterExpanded, setFilterExpanded] = useAtom(doListFilterExpandedAtom);
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
  const [todayChecked, setTodayChecked] = useState(true);
  const [pastChecked, setPastChecked] = useState(false);
  const [upcomingChecked, setUpcomingChecked] = useState(false);
  const { storeList } = useStoreList();
  const storeInputList = storeList.map((store) => ({
    label: `${store._unit} - ${store._hawkerStore}` ?? '',
    value: store._hscode ?? '',
  }));
  const watchedHscode = form.watch('_hscode');

  useEffect(() => {
    // default
    let from = '';
    let to = '';
    // from
    if (upcomingChecked) from = tomorrow;
    if (todayChecked) from = today;
    if (pastChecked) from = '';
    // to
    if (pastChecked) to = yesterday;
    if (todayChecked) to = today;
    if (upcomingChecked) to = '';
    // set values
    form.setValue('_expectedDateTo', to);
    form.setValue('_expectedDateFrom', from);
  }, [pastChecked, todayChecked, upcomingChecked]);

  function resetForm() {
    setPastChecked(false);
    setTodayChecked(true);
    setUpcomingChecked(false);
    form.setValue('_hscode', '');
  }

  function getFilterText() {
    const filterStore = storeList.find(
      (store) => store._hscode === watchedHscode,
    );
    const filterTextArray: string[] = [];
    if (filterStore) filterTextArray.push(filterStore._hawkerStore ?? '');
    if (todayChecked) filterTextArray.push(t('today'));
    if (pastChecked) filterTextArray.push(t('past'));
    if (upcomingChecked) filterTextArray.push(t('upcoming'));
    return filterTextArray.join(', ');
  }

  return (
    <>
      <List.Accordion
        title={t('filter')}
        left={(props) => (
          <>
            <List.Icon {...props} icon="filter" />
            {_.isEmpty(watchedHscode) ? null : <Badge>1</Badge>}
          </>
        )}
        expanded={filterExpanded}
        onPress={() => setFilterExpanded(!filterExpanded)}
      >
        <>
          <SelectTextInput
            search
            mode="outlined"
            label={t('store')}
            style={{
              marginHorizontal: SPACING.m,
            }}
            inputList={storeInputList}
            value={watchedHscode}
            onSelected={(item) => {
              form.setValue('_hscode', item.value);
            }}
          />
        </>
        <View
          style={[
            CommonStyles.row,
            {
              justifyContent: 'space-between',
              marginEnd: SPACING.m,
              marginStart: -SPACING.l,
            },
          ]}
        >
          <FilterCheckbox
            label={t('today')}
            onPress={() => setTodayChecked(!todayChecked)}
            checked={todayChecked}
          />
          <FilterCheckbox
            label={t('past')}
            onPress={() => setPastChecked(!pastChecked)}
            checked={pastChecked}
          />
          <FilterCheckbox
            label={t('upcoming')}
            onPress={() => setUpcomingChecked(!upcomingChecked)}
            checked={upcomingChecked}
          />
        </View>
        <>
          <Button
            mode="contained"
            onPress={resetForm}
            style={{
              marginHorizontal: SPACING.m,
            }}
          >
            {t('reset')}
          </Button>
        </>
      </List.Accordion>
      {!filterExpanded && (
        <Text
          variant="bodyMedium"
          style={{
            fontStyle: 'italic',
            color: MD2Colors.blue800,
            marginHorizontal: SPACING.m,
          }}
        >
          {`${t('filter')}: ${getFilterText()}`}
        </Text>
      )}
    </>
  );
}

interface FilterCheckboxProps {
  label: string;
  checked: boolean;
  onPress: () => void;
}

function FilterCheckbox({ label, checked, onPress }: FilterCheckboxProps) {
  return (
    <Pressable
      style={[
        CommonStyles.row,
        {
          alignItems: 'center',
          marginVertical: SPACING.xs,
        },
      ]}
      onPress={onPress}
    >
      <Checkbox status={checked ? 'checked' : 'unchecked'} onPress={onPress} />
      <Text variant="bodyMedium">{label}</Text>
    </Pressable>
  );
}
