import { useHeaderHeight } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { CustomError } from 'base/src/data/CustomError';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { KeyboardDismissView } from 'base/src/ui/components/KeyboardDismissView';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import {
  HawkerUserRepo,
  UserRepoError,
} from 'ehawker/src/data/repositories/HawkerUserRepo';
import { LoginRequest } from 'ehawker/src/data/schemas/auth/LoginRequestSchema';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Image } from 'expo-image';
import * as SplashScreen from 'expo-splash-screen';
import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import {
  Button,
  Checkbox,
  HelperText,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';

import { navColorAtom } from '../../navigation/NavBar';
import { NavigationProps } from '../../navigation/Routes';

const log = loggerWithTag('ForgetPasswordPage.tsx');

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    width: '85%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    marginTop: 10,
  },
  submitBtn: {
    marginTop: 20,
  },
  logo: {
    width: '85%',
    height: 100,
  },
  logoContainer: {
    position: 'absolute',
    top: -10,
    width: '100%',
    alignItems: 'center',
  },
  rememberMeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10, // Adjust as needed
  },
});

export default function ForgetPasswordPage() {
  log.debug('ForgetPasswordPage rendered');
  const { t } = useTranslate();
  const { errorDialog } = useErrorHelper();
  const { colors } = useTheme();
  const { addDialog } = useDialog();
  const headerHeight = useHeaderHeight();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [loading, setLoading] = useState(false);

  const backgroundColor = colors.background;
  const setNavColor = useSetAtom(navColorAtom);
  const navigation = useNavigation<NavigationProps>();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setNavColor(backgroundColor);
    return () => setNavColor('');
  }, [backgroundColor, setNavColor]);

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  return (
    <KeyboardDismissView>
      <PageContainer
        customStyles={{
          backgroundColor: colors.background,
        }}
      >
        <View
          style={[
            CommonStyles.container,
            styles.outerContainer,
            { backgroundColor: colors.background },
          ]}
        >
          <View style={styles.logoContainer}>
            <Image
              style={styles.logo}
              contentFit="contain"
              source={require('../../../../assets/common/logo.png')}
            />
          </View>
          <View style={{ ...styles.loginContainer, marginTop: -headerHeight }}>
            <TextInput
              style={styles.textInput}
              mode="outlined"
              label={t('username')}
              error={!usernameTouched && usernameError}
              textContentType="username"
              autoCapitalize="none"
              onPressIn={() => setUsernameTouched(true)}
              onFocus={() => setUsernameTouched(true)}
              disabled={loading}
              onChangeText={(text) => {
                setUsername(text);
                setUsernameTouched(true);
              }}
            />
            {usernameError && !usernameTouched && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
            <TextInput
              style={styles.textInput}
              mode="outlined"
              label={t('email')}
              error={!emailTouched && emailError}
              textContentType="emailAddress"
              onPressIn={() => setEmailTouched(true)}
              onFocus={() => setEmailTouched(true)}
              disabled={loading}
              onChangeText={(text) => {
                setEmail(text);
                setEmailTouched(true);
              }}
            />
            {emailError && !emailTouched && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}

            {emailFormatError && !emailTouched && (
              <HelperText type="error">{t('not_in_valid_format')}</HelperText>
            )}

            <Button
              style={styles.submitBtn}
              mode="contained"
              loading={loading}
              disabled={loading}
              onPress={submitClicked}
            >
              {t('submit')}
            </Button>
          </View>
          <View style={styles.bottomContainer}>
            <Text variant="bodySmall">
              {t('version', {
                version:
                  Application.nativeApplicationVersion ??
                  Constants.expoConfig?.extra?.version ??
                  '-',
                build:
                  Application.nativeBuildVersion ??
                  Constants.expoConfig?.extra?.build ??
                  '-',
              })}
            </Text>
            <Text variant="bodySmall">
              {t('copyright_line1', { year: new Date().getFullYear() }) +
                ' ' +
                t('copyright_line2')}
            </Text>
          </View>
        </View>
      </PageContainer>
    </KeyboardDismissView>
  );

  async function submitClicked() {
    log.info('Submit button clicked');
    if (username.trim().length === 0) {
      log.info('Username is empty');
      setUsernameError(true);
      setUsernameTouched(false);
      return;
    }
    if (email.trim().length === 0) {
      log.info('Email is empty');
      setEmailError(true);
      setEmailTouched(false);
      return;
    }

    if (!emailRegex.test(email.trim())) {
      log.info('Email is not in valid format');
      setEmailFormatError(true);
      setEmailTouched(false);
      return;
    }
    setLoading(true);
    await forgetPassword();
  }

  async function forgetPassword() {
    log.info('LoginPage, navigate to dashboard page');
    Keyboard.dismiss();
    try {
      await HawkerUserRepo.forgetPassword({
        _userName: username,
        _emailid: email,
      });
      return addDialog({
        message: t('forget_password_submitted_successfully'),
        primaryButton: t('ok'),
        primaryButtonAction: async () => {
          try {
            navigation.goBack();
          } catch (error) {
            errorDialog(error as Error);
          }
        },
      });
    } catch (error) {
      if (error instanceof CustomError) {
        switch (error.type) {
          case UserRepoError.INVALID_PASSWORD:
            return addDialog({
              message: t('invalid_password'),
              mode: 'error',
            });
        }
      }
      errorDialog(error as Error);
    } finally {
      setLoading(false);
      log.info('Password reset successfully');
    }
  }
}
